import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react'
import AcitvityTabs from './AcitvityTabs';
import UpdateActivity from './UpdateActivity';
import "./edit_activity.css"
import getCandidateRequisitionByRequisitionIdAndCandidateEmail from '../../apis/getCandidateRequisitionByRequisitionIdAndCandidateEmail/getCandidateRequisitionByRequisitionIdAndCandidateEmail';
import getRequisitionDetailsbyId from '../../apis/getRequisitionDetailsbyId/getRequisitionDetailsbyId';
import { UserTypeContext } from '../../AppRoute';
import { CircularProgress } from '@material-ui/core';


const EditActivity = ({ edit, classes, EditIcon, setEdit, reqId, email, props, selectedCandReq,moveCandidate,allRequisitionData }) => {

    const { userType, userIdType, userRecords } = useContext(UserTypeContext);
    const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId;
    const userRole = userType === 'Admin' ? props?.location?.state?.userType : userRecords?.role?.roleName;

    const [update, setUpdate] = useState(true)
    const [selectedEventIndex, setSelectedEventIndex] = useState(null)
    const [canReqData, setCanReqData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reqDetails, setReqDetails] = useState(false)





    // fetch candidate requisition details 
    const getCandidateRequisition = async () => {
        let  payload={ requisitionId: reqId, email,recruitmentpartnerId:userRecruitmentpartnerId }
        
      
        
        let resp = await getCandidateRequisitionByRequisitionIdAndCandidateEmail(payload);

        if (resp?.status === 200 && resp?.data?.data){         
            resp=resp.data.data
            setCanReqData(resp)
        }
        
    }


    // fetch  requisition details for the selected reuquisition
    const getReqDetailsbyId = async () => {
        
        
        let payload = {
            userType: userType === 'Admin' ? userRole : userType, 
            recruitmentpartnerId: userRecruitmentpartnerId,
            userRecruitmentpartnerId:userRecruitmentpartnerId,
            userId:userRecords.id

        }

        let resp = await getRequisitionDetailsbyId(reqId, payload)

        if (resp?.status === 200 && resp?.data?.data[0]) {
            let { reqreferenceId = "", placementType = "" } = resp?.data?.data[0]
            setReqDetails({ reqreferenceId, placementType })
        }

       
    }

    // run evertime modal is opened or different candidate is selected
    useEffect(() => {
         
        if (!edit)
            return
        
        const onMount=async()=>{
            setLoading(true)
            await getCandidateRequisition()
            await getReqDetailsbyId()
            setLoading(false)
        }

        onMount()

    }, [email, reqId, edit])


    // closing the modal
    const closeEdit = () => {
        setEdit(false);
        setTimeout(() => setUpdate(true), 1000)
    }

    return (
        <>
            {
                <Dialog
                    open={edit}
                    aria-labelledby="dialog-title"
                    maxWidth="sm"
                    fullWidth
                    className="add-division"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        className={`${classes.title} responsive_title`}
                    >
                        <div className="activit_dialog_header">

                            <div className="pd_top_10"> <img src={EditIcon} height={35} width={35} alt="" />&nbsp;&nbsp;</div>
                            <div><span className="activity_dialog">Edit Action</span></div>

                        </div>
                    </DialogTitle>

                    <DialogContent className="division-dialog-content">

                        {
                            //toggle between loading, event selection and edit of selected event
                            loading ?
                                <div className='loaderForEdit'><CircularProgress /></div> :
                                update ?
                                    <>
                                        <AcitvityTabs setUpdate={setUpdate} data={canReqData?.eventStatus} setIndex={setSelectedEventIndex} selectedCandReq={selectedCandReq}
                                        closeEdit={closeEdit}
                                        activCount={props?.location?.state?.activCount}
                                        moveCandidate={moveCandidate}
                                        userType={userType}
                                        id={canReqData?._id}
                                        userIdType={userIdType}
                                        />
                                       
                                       
                                        <button
                                            type="button"
                                            className="cancel-button-division edit_activity_cancel"
                                            onClick={closeEdit}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                    :
                                    <>
                                        <UpdateActivity  classes={classes} selectedEventIndex={selectedEventIndex} canReqData={canReqData}  closeEdit={closeEdit} reqDetails={reqDetails} setUpdate={setUpdate} user={userIdType} userRecruitmentpartnerId={userRecruitmentpartnerId} moveCandidate={moveCandidate} email={email} allRequisitionData={allRequisitionData}/>
                                    </>
                        }
                    </DialogContent>
                </Dialog>
            }
        </>

    )
}

export default EditActivity
