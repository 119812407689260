import { useState, useCallback, useEffect } from "react";
import {
    Grid as GridDiv,
    GridColumn,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import debounce from "lodash/debounce";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import * as XLSX from "xlsx";
import { NotificationManager } from "react-notifications";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { convertDate } from "../AcitivityModal/assistingFunctions";
import accessReport from "../../apis/accessLogReport/accessReport";
// import { convertDate } from "./AcitivityModal/assistingFunctions";
const useStyles = makeStyles((theme) => ({
    odd: {
        background: "#F6F6F6 !important",
    },
    even: {
        background: "#fffff !important",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    waitingText: {
        marginLeft: "5px",
    },
}));
const AccessReport = (props) => {
    const {
        userType,
        userIdType,
        userRecruitmentpartnerId,
        startSelectedDate,
        endSelectedDate,
        exports,
        additionalFilter,
        setTriggerAccess
    } = props;


    const [page, setPage] = useState({ skip: 0, take: 10 });
    const [isExporting, setIsExporting] = useState(false);
    const classes = useStyles();

    const [dataResult, setDataResult] = useState({});
    const [loading, setLoading] = useState(true)
    const columns = [
        { title: "Name", "field": "displayName", "width": "180px" },
        { title: "Email", "field": "displayEmail", "width": "180px" },
        { title: "Create GroupList", "field": "Create_Group_List", "width": "180px" },
        { title: "Create Job", "field": "Create_Job", "width": "180px" },
        { title: "Download CV", "field":"Download_CV", "width": "180px" },
        { title: "Interview Activity", "field": "Interview_Activity", "width": "180px" },
        { title: "Job Search", "field": "Job_Search", "width": "180px" },
        { title: "Manage Team", "field": "Manage_Team", "width": "180px" },
        { title: "Resource Search", "field": "Resource_Search", "width": "180px" },
        { title: "Run Report", "field": "run_report", "width": "180px" },
        { title: "Save Job", "field": "Save_Job", "width": "180px" },
        { title: "Start Activity", "field": "Start_Activity", "width": "180px" },
        { title: "Submit Activity", "field": "Submit_Activity", "width": "180px" },
        { title: "Talent Search", "field": "Talent_Search", "width": "180px" },
        { title: "Update Job Users", "field": "Update_Job_Users", "width": "180px" },
        { title: "Upload Resume", "field": "Upload_Resume", "width": "180px" },
        { title: "User Login", "field": "user_login", "width": "180px" },
        { title: "User Logout", "field": "user_logout", "width": "180px" },
        { title: "View Candidate", "field": "View_Candidate", "width": "180px" },
        { title: "View Job", "field": "View_Job", "width": "180px" },
        { title: "IP Adresses", "field": "ipAddresses", "width": "180px" },
    ]

    const debouncedSearch = useCallback(
        debounce(
            async (
                page,
                additionalFilter,
                startSelectedDate,
                endSelectedDate
            ) => {

                try {
                    setDataResult({});

                    let payload = {
                        "userRecruitmentpartnerId": userRecruitmentpartnerId,
                        "page": page.skip / page.take + 1,
                        "size": page.take,
                        "formattedDatestart": startSelectedDate,
                        "formattedDateend": endSelectedDate,
                        "isExport": false,
                        "userId": userIdType,
                        "role": userType,
                        "additionalFilter": additionalFilter
                    }

                    const search = await accessReport(payload);

                    if (search?.totalCount[0].total>=0) {

                        let total = search?.totalCount[0].total
                        let data = search?.data

                        setDataResult({ data, total });
                    }
                    else setDataResult({ data: null })
                }
                catch (error) {
                    setDataResult({ data: null })
                    setLoading(false)

                }
                setLoading(false)
            },
            1000
        ),
        []
    );


    useEffect(() => {
        const startDate = convertDate(startSelectedDate);
        const endDate = convertDate(endSelectedDate);

        if (!startSelectedDate || !endSelectedDate || startDate > endDate) {
            setDataResult({})
            NotificationManager.error("Please enter a valid date", "Error", 2500);
        } else {
            setLoading(true)
            debouncedSearch(page, additionalFilter, startDate, endDate);
        }
    }, [page, startSelectedDate, endSelectedDate, additionalFilter]);

    useEffect(() => {
        if (exports)
            callApiToGenerateExcel()
    }, [exports])

    const callApiToGenerateExcel = async () => {
        let payload = {
            "userRecruitmentpartnerId": userRecruitmentpartnerId,
            "page": 1,
            "size": 100,
            "formattedDatestart": convertDate(startSelectedDate),
            "formattedDateend": convertDate(endSelectedDate),
            "isExport": true,
            "userId": userIdType,
            "role": userType,
            "additionalFilter": additionalFilter
        }
        let search = await accessReport(payload);
        if (dataResult?.data.length <= 0) {
            NotificationManager.error("No data available", "Error", 2500);
            setIsExporting(false);
            setTriggerAccess(false)
            return;
        }
        
        let exportData = search?.data.map((ele) => {
            let ipAddress = ele["ipAddresses"].map((ip) => `${ip}`).join(", ");
            return { ...ele, "ipAddresses": ipAddress };
        });
        setIsExporting(true);
        // let exportData = search?.data

        const ws = XLSX.utils.json_to_sheet(exportData);  

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, "Access log report.xlsx");
        setTriggerAccess(false)
        setIsExporting(false);     
    };

    const jobReportSkeleton = () => {
        let arr = [];
        for (let i = 0; i <= 10; i++) {
            arr.push(
                <Skeleton
                    key={"HiringReportSkeleton" + i}
                    className={i % 2 === 0 ? classes.odd : classes.even}
                    variant="rect"
                    width="100%"
                    height="50px"
                />
            );
        }
        return arr;
    };

    const changePageSize = (e) => {
        if (loading)
            return
        setPage((elem) => {
            return {
                ...elem,
                take: e.dataState.take
            }
        });
    };

    const handlePageChange = (e) => {
        if (loading)
            return
        setPage({
            skip: e.page.skip,
            take: e.page.take
        });
    };


    return (
        <>
            <Backdrop className={classes.backdrop} open={isExporting}>
                <CircularProgress color="inherit" />
                <Typography className={classes.waitingText}>
                    {" "}
                    Please wait ...
                </Typography>
            </Backdrop>
            <GridDiv
                filterable={false}
                ortable={true}
                resizable={true}
                reorderable={true}
                pageable={{
                    pageSizes: [10, 20, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10,
                }}
                skip={page.skip}
                take={page.take}
                data={dataResult?.data}
                total={dataResult?.total || 0}
                onPageChange={handlePageChange}
                onDataStateChange={changePageSize}
                className="kendo_reports"
            >
                <GridNoRecords>
                    {loading ? jobReportSkeleton() : "No results found !"}
                </GridNoRecords>

                {columns && columns.map((column) => (
                    <GridColumn
                        key={column.field}
                        field={column.field}
                        // title={column.title}
                        width={column.width}
                        filterable={false}
                        title={column.title}
                        {...(column.cell && { cell: column.cell })}
                        {...(column.format && { format: column.format })}
                    />
                ))}
            </GridDiv>
        </>
    );
};


export default AccessReport
