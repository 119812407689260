import React, { useState, useEffect, useContext } from 'react'
import Header from "../../components/headerAdmin/Header"
import Footer from "../../components/adminFooter/Footer.js";
import "./createRequisition.css"
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { UserTypeContext } from "../../AppRoute";
import { Button, FormControl, OutlinedInput, TextField, Dialog, DialogActions, DialogContent, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import countriesList from "../../utilities/country-state-city/dist/lib/country.json"
import csc from "../../utilities/country-state-city/dist";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import createRequisition from '../../apis/createRequisition/createRequisition';
import getTotalYearsExperience from '../../apis/experience/getTotalYearsOfExp';
import GetAllRequisitionStatus from "../../apis/getallRequisitionStatus/getAllRequisitionStatus"
import { FilePicker } from "react-file-picker";
import pdfIcon from "../myTalentCommunity/icons/pdf.svg";
import view from "../../components/basicInfoForm/images/doc.png";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { BlobServiceClient } from '@azure/storage-blob';
import jsonConfig from "../../Config.json";
import BasicHeader from "../../components/basicHeader/BasicHeader.js"
import recruiterLeadernameByDivision from "../../apis/recruiterLeadernameByDivision/recruiterLeadernameByDivision";
import { getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import configJSON from "../../Config.json";
import { useLocation } from 'react-router-dom';
import getLocations from '../../apis/getLocations/getLocations';
import spocByClientid from '../../apis/spocByClientid';


const CreateRequisition = (props) => {
    const location = useLocation();
    const requisitionDetailsData = location?.state?.requisitionData;
    const isClone = location?.state?.isClone
    const divisionSet = location?.state?.divisionName
    const { userType, userRecruitmentpartnerId, userRecords } = useContext(UserTypeContext);
    const [placementTypeOption, setPlacementTypeOption] = useState([
        { PositionType: "Direct Placement" }, { PositionType: "Right To Hire" }, { PositionType: "Full Time" }, { PositionType: "Contract" }
    ]);
    const [clientNameOption, setClientNameOption] = useState([])
    const [divisionName, setDivisionName] = useState([])
    const [pocName, setPocName] = useState([])
    const [countryList, setCountryList] = useState(countriesList);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [currencyBillRate, setCurrencyBillRate] = useState([
        { value: "INR/HOUR" }, { value: "INR/DAY" }, { value: "INR/YEAR" }
    ]);
    const [priority, setPriority] = useState([
        { name: "High" }, { name: "Low" }
    ]);
    const [status, setStatus] = useState([]);
    const [isSubmited, setIsSubmited] = useState(false)
    const [experienceLevel, setExperienceLevel] = useState([])
    const [startSelectedDate, setStartSelectedDate] = useState(new Date());
    const [endDateSelectedDate, setEndDateSelectedDate] = useState(new Date());
    const [selectedFile, setSelectedFile] = useState({});
    const [allRecruiterLeader, setallRecruiterLeader] = useState([]);
    const [allCityList, setAllCityList] = useState(csc.getAllCities())
    const [isDataSaved, setIsDataSaved] = useState(false);
    const [locationSearchText, setLocationSearchText] = useState("")
    const [locationLoading, setLocationLoading] = useState(false)
    const [locationList, setLocationList] = useState([])
    const defaultData = {
        primarySkill: "",
        placementType: "",
        clientJobId: "",
        clientName: "",
        endClientName: "",
        division: "",
        location: [],
        minimumBillRate: "",
        maxiMumBillRate: "",
        currency: "",
        fee: "",
        openings: "",
        minimumExperienceLevel: "",
        maximumExperienceLevel: "",
        priority: { name: "High" },
        status: "",
        filledBy: "",
        jobDescription: "",
        resumeUrl: "",
        assignToValue: "",
        poc: ""
    }
    const [requisitionData, setRequisitionData] = useState(isClone ? requisitionDetailsData : defaultData)
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const getAllClientByOrg = async () => {
        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId,
            isActive: true
        }
        if (isClone)
            getpoc(requisitionData.clientName._id, userRecruitmentpartnerId)

        try {
            let response = await getClientByOrg(payload);
            setClientNameOption(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getpoc = async (clientId, recruitmentpartnerId) => {
        try {
            let response = await spocByClientid(clientId, recruitmentpartnerId);
            const arr = response.data.data.flatMap(item => item);
            setPocName(arr || [])
        } catch (error) {
            console.log(error)
        }
    }

    const recruiterLeaderByDivision = async (id) => {
        try {
            let recruiterLeaderData = await recruiterLeadernameByDivision(id, userRecruitmentpartnerId);
            recruiterLeaderData = recruiterLeaderData.data?.data;
            setallRecruiterLeader(recruiterLeaderData ? recruiterLeaderData.map(item => item.recruiterLeader) : []);

        } catch (error) {
            console.log(error);
        }
    };

    const getFilteredRecruiterLeaderOptions = () => {
        let filteredOptions = allRecruiterLeader.filter(item => item.id !== requisitionData.assignToValue.id)
        return filteredOptions
    }


    const getStatusAndExperience = async () => {
        try {
            const TotalExpYearresponse = await getTotalYearsExperience();
            setExperienceLevel(TotalExpYearresponse);
            let defaultResponseExperience = TotalExpYearresponse.filter((item) => item.name === "<1 year")
            let response = await GetAllRequisitionStatus();
            setStatus(response.data.data)
            let defaultResponseStatus = response.data.data.filter((item) => item.statusCode === configJSON?.requisitionStatusCodes?.OPEN)
            if (!isClone) {
                setRequisitionData({ ...requisitionData, status: defaultResponseStatus[0], experienceLevel: defaultResponseExperience[0] })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getLocationResult = async (searchText) => {
        try {
            let response = await getLocations(searchText)
            response = response.data.data
            let locationArr = []
            response.forEach((item) => {
                let str = ""
                str += (item.name).trim()
                if (item.state) {
                    str += ", " + (item?.state?.name).trim()
                }
                if (item.country) {
                    str += ", " + (item?.country?.name).trim()
                }
                locationArr.push(str)
            })
            setLocationList(locationArr)
            setTimeout(() => {
                setLocationLoading(false);
            }, 1000);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (locationSearchText.length > 0) {
                setLocationLoading(true)
                getLocationResult(locationSearchText)
            }
            else {
                setLocationLoading(false)
                setLocationList([])
            }
        }, 700);

        return () => clearTimeout(timeout);
    }, [locationSearchText])




    useEffect(() => {
        getStatusAndExperience()
        getAllClientByOrg()
    }, [])


    useEffect(() => {
        if (isClone) {
            recruiterLeaderByDivision(requisitionDetailsData?.division?._id)
            setRequisitionData({ ...requisitionData, endClientName: requisitionDetailsData.endClientName })
            setRequisitionData({ ...requisitionData, clientJobId: requisitionDetailsData.clientJobId })
            setRequisitionData({ ...requisitionData, assignToValue: requisitionDetailsData.assignedTo })
            setRequisitionData({ ...requisitionData, fee: requisitionDetailsData.fee });
            setRequisitionData({ ...requisitionData, minimumBillRate: requisitionDetailsData.minimumBillRate });
            setRequisitionData({ ...requisitionData, maxiMumBillRate: requisitionDetailsData.maxiMumBillRate });
            setRequisitionData({ ...requisitionData, openings: requisitionDetailsData.openings });
            setRequisitionData({ ...requisitionData, location: requisitionDetailsData.location });
            setDivisionName(divisionSet);
        } else {
            return;
        }
    }, [])

    const startDateChangeHandler = (date) => {
        setStartSelectedDate(date);
    };

    const endDateChangeHandler = (date) => {
        setEndDateSelectedDate(date);
    }

    const primarySkillHandler = (event) => {
        setRequisitionData({ ...requisitionData, primarySkill: event.target.value });
    }

    const placementTypeHandler = (e, value) => {
        if (value) {
            setRequisitionData({ ...requisitionData, placementType: value });
        } else {
            setRequisitionData({ ...requisitionData, placementType: "" });
        }
    }

    const clientJobIdHandler = (event) => {
        setRequisitionData({ ...requisitionData, clientJobId: event.target.value })
    }

    const ClientNameHandler = (e, value) => {
        setallRecruiterLeader([])
        if (value) {
            setRequisitionData({ ...requisitionData, clientName: value, division: "", assignToValue: "", poc: "" });
            const divisions = value.assignedDivisions;
            let activeDivisions = divisions.flatMap(item => item);
            activeDivisions = activeDivisions.filter(item => item.isActive === true)
            getpoc(value._id, value.recruitmentpartnerId)
            setDivisionName(activeDivisions);

        } else {
            setRequisitionData({ ...requisitionData, clientName: "", division: "", assignToValue: "", poc: "" });
        }
    }

    const endClientNameHandler = (event) => {
        setRequisitionData({ ...requisitionData, endClientName: event.target.value });
    }

    const divisionChangeHandler = (e, value) => {
        setallRecruiterLeader([])
        if (value) {
            setRequisitionData({ ...requisitionData, division: value, assignToValue: "" });
            recruiterLeaderByDivision(value._id)
        } else {
            setRequisitionData({ ...requisitionData, division: "", assignToValue: "" });
        }
    }
    const pocChangeHandler = (e, value) => {
        if (value) {
            setRequisitionData({ ...requisitionData, poc: value });
        } else {
            setRequisitionData({ ...requisitionData, poc: "" });
        }
    }

    const minimumbillRateChangeHandler = (event) => {
        setRequisitionData({ ...requisitionData, minimumBillRate: event.target.value });
    }

    const MaximumbillRateChangeHandler = (event) => {
        setRequisitionData({ ...requisitionData, maxiMumBillRate: event.target.value });
    }

    const currencyBillRateChangeHandler = (e, value) => {
        if (value) {
            setRequisitionData({ ...requisitionData, currency: value });
        } else {
            setRequisitionData({ ...requisitionData, currency: "" });
        }
    }

    const feeChangeHandler = (event) => {
        setRequisitionData({ ...requisitionData, fee: event.target.value });
    }

    const openingChangeHandler = (event) => {
        setRequisitionData({ ...requisitionData, openings: event.target.value });
    }

    const minimumExperienceHandler = (event) => {
        setRequisitionData({ ...requisitionData, minimumExperienceLevel: event.target.value });
    }

    const maximumExperienceHandler = (event) => {
        setRequisitionData({ ...requisitionData, maximumExperienceLevel: event.target.value });
    }


    const priorityChangeHandler = (e, value) => {
        if (value) {
            setRequisitionData({ ...requisitionData, priority: value });
        } else {
            setRequisitionData({ ...requisitionData, priority: "" });
        }
    }

    const statusChangeHandler = (e, value) => {
        if (value) {
            setRequisitionData({ ...requisitionData, status: value });
        } else {
            setRequisitionData({ ...requisitionData, status: "" });
        }
    }

    const filledByChangeHandler = (event) => {
        setRequisitionData({ ...requisitionData, filledBy: event.target.value });

    }

    const assignToChangeHandler = (e, value) => {
        if (value) {
            setRequisitionData({ ...requisitionData, assignToValue: value });
        } else {
            setRequisitionData({ ...requisitionData, assignToValue: "" });
        }

    }

    const jobDescriptionChangeHandler = (event) => {
        setRequisitionData({ ...requisitionData, jobDescription: event.target.value });
    }

    const gotoDashBoard = () => {
        window.location.href = '/requisition-dashboard'
    }


    const cancelHandler = () => {
        setRequisitionData({
            primarySkill: "",
            placementType: "",
            clientJobId: "",
            clientName: "",
            endClientName: "",
            division: "",
            location: [],
            minimumBillRate: "",
            maxiMumBillRate: "",
            currency: "",
            fee: "",
            openings: "",
            experienceLevel: "",
            priority: "",
            status: "",
            fillBy: "",
            jobDescription: "",
            assignToValue: ""
        })
        setStartSelectedDate("")
        setEndDateSelectedDate("")
        gotoDashBoard()
    }

    const closeModal = () => {
        setmodalIsOpen(false);
    }

    const openModel = () => {
        setmodalIsOpen(true);
    }

    const getLocationsBreakDown = (locations) => {
        let city = []
        let state = []
        let country = []
        locations.forEach((item) => {
            let itemArr = item.split(",")
            if (itemArr.length === 1) {
                country.push(itemArr[0].trim())
                state.push(null)
                city.push(null)
            }
            else if (itemArr.length === 2) {
                state.push(itemArr[0].trim())
                country.push(itemArr[1].trim())
                city.push(null)
            }
            else {
                city.push(itemArr[0].trim())
                state.push(itemArr[itemArr.length - 2].trim())
                country.push(itemArr[itemArr.length - 1].trim())
            }
        })
        let locationObj = {
            city: city,
            state: state,
            country: country
        }
        return locationObj;
    }


    const saveHandler = async () => {
        setIsSubmited(true);
        setIsDataSaved(false)
        if (requisitionData.primarySkill.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Primary Skill",
                "Error",
                2500
            );
        }
        else if (requisitionData.clientName.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Client Name",
                "Error",
                2500
            );
        }
        else if (requisitionData.location.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Location",
                "Error",
                2500
            );
        }

        else if (requisitionData.openings.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Openings",
                "Error",
                2500
            );
        }
        else if (requisitionData.assignToValue.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Assign To",
                "Error",
                2500
            );
        } else if (requisitionData.division.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Division",
                "Error",
                2500
            );
        } else if (requisitionData.priority.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Priority",
                "Error",
                2500
            );
        }
        else if (requisitionData.maximumExperienceLevel === "") {
            NotificationManager.error(
                "Please enter Maximum Experience",
                "Error",
                2500
            );
        }
        else if (requisitionData.minimumExperienceLevel === "") {
            NotificationManager.error(
                "Please enter Minimum Experience",
                "Error",
                2500
            );
        }
        else if (requisitionData.placementType.length === 0) {
            setmodalIsOpen(false);
            await NotificationManager.error(
                "Please enter Placement Type",
                "Error",
                2500
            );
        }
        else if (parseInt(requisitionData.maxiMumBillRate) < parseInt(requisitionData.minimumBillRate)) {
            await NotificationManager.error(
                "Minimum Bill Rate cannot be more than  Maximum Bill Rate.",
                "Error",
                2500
            );
        }
        else if (parseInt(requisitionData.maximumExperienceLevel) < parseInt(requisitionData.minimumExperienceLevel)) {
            await NotificationManager.error(
                "Minimum Experience  cannot be more than  Maximum Experience.",
                "Error",
                2500
            );
        }

        else if ((requisitionData.maxiMumBillRate !== "" && requisitionData.minimumBillRate !== "") && requisitionData.currency === '') {
            await NotificationManager.error(
                "Please enter Currency Unit",
                "Error",
                2500
            );

        }
        else if (requisitionData.maximumExperienceLevel < 1) {
            await NotificationManager.error(
                "Maximum years of Experience cannot be less than 1 year"
            )
        }
        else {
            let url = ""
            if (selectedFile?.name) {
                url = await uploadResumeToBlob(selectedFile)
            }

            let locationObj = getLocationsBreakDown(requisitionData.location)

            let payload = {
                primarySkill: requisitionData.primarySkill,
                placementType: requisitionData.placementType.PositionType,
                clientJobId: requisitionData.clientJobId,
                clientId: requisitionData.clientName._id,
                endClientName: requisitionData.endClientName,
                divisionId: requisitionData.division._id,
                city: locationObj.city,
                state: locationObj.state,
                country: locationObj.country,
                location: requisitionData.location,
                minimumBillRate: requisitionData.minimumBillRate,
                maxiMumBillRate: requisitionData.maxiMumBillRate,
                currency: requisitionData.currency?.value,
                fee: requisitionData.fee,
                startDate: startSelectedDate,
                endDate: endDateSelectedDate,
                openings: requisitionData.openings,
                maxExperience: requisitionData?.maximumExperienceLevel,
                minExperience: requisitionData?.minimumExperienceLevel,
                priority: requisitionData.priority.name,
                filledBy: requisitionData.filledBy,
                recruitmentLeaderId: requisitionData.assignToValue.id,
                jobDescription: requisitionData.jobDescription,
                uploadDocuments: url,
                misOperatorId: userRecords.id,
                orgId: userRecords.recruitmentpartnerId,
                misOperatorDetails: { id: userRecords.id, name: userRecords.displayName },
                assignToDetails: { id: requisitionData.assignToValue.id, email: requisitionData.assignToValue.email },
                userId: userRecords.id,
                spocId: requisitionData.poc._id
            }
            if (requisitionData.status.statusCode === configJSON?.requisitionStatusCodes?.OPEN) {
                let subStatus = (requisitionData.status.subList.filter((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED))[0]
                payload.status = {
                    subCode: subStatus.subCode, statusCode: requisitionData.status.statusCode,
                    statusCodeId: Object(requisitionData.status._id), subCodeId: Object(subStatus._id)
                }
            } else {
                let openStatus = status.find((item) => item.statusCode === configJSON?.requisitionStatusCodes?.OPEN)
                let subStatus_unassigned = openStatus?.subList.find((item) => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED)

                payload.status = {
                    statusCode: requisitionData.status.statusCode,
                    statusCodeId: Object(requisitionData.status._id),
                    subCode: configJSON.requisitionStatusCodes.OPENSUBSTATUS.UNASSIGNED,
                    subCodeId:Object(subStatus_unassigned?._id)
                }
            }
            try {
                setIsSubmited(false)
                setIsDataSaved(true)
                const addRequisition = await createRequisition(payload);
                if (addRequisition.status === 200) {
                    NotificationManager.success(
                        `${addRequisition.data.message}`,
                        "Success",
                        2500
                    );
                    setTimeout(() => {
                        gotoDashBoard();
                    }, 2000);

                }
                else {
                    NotificationManager.error("Requisition does not added", "Error", 2500);
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    NotificationManager.error(error.response.data.errMessage, "Error", 2500);
                    setmodalIsOpen(false);
                    setIsDataSaved(false);
                }
            }
        }

    }

    const handleFileSelect = (file) => {
        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
            NotificationManager.error(
                "Only PDF and DOC files are allowed",
                "Error",
                2500
            );
        } else {
            setSelectedFile(file);
        }
    }

    const fileExtensionuploadResume = () => {
        let extension = selectedFile?.name.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
            return <img src={pdfIcon} alt="PDF Icon" className="new_candidate_icon_createRequisition" />
        } else if (extension === 'doc' || extension === 'docx') {
            return <img src={view} alt="DOC Icon" className="new_candidate_icon_createRequisition" />
        }
    }

    const handleclose = () => {
        setSelectedFile({})
    }

    function create_UUID() {
        var dt = new Date().getTime();
        var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return uuid;
    }


    const uploadResumeToBlob = async (blob) => {
        const uuid = create_UUID();
        const sas = jsonConfig.sasToken;
        decodeURI(sas);
        const bsClient = new BlobServiceClient(
            // `https://${blobStorageAccount}.blob.core.windows.net${sas}`
            `${jsonConfig.ctResourcesCdn + sas}`
        );
        var v = blob.name.split(".")[1];
        var c = uuid + "." + v;
        const containerClient = bsClient.getContainerClient("requisition/resume");
        const blobName = userRecords.id + "/" + c;
        const url = `${jsonConfig.ctResourcesCdn}/requisition/resume/${blobName}`;
        const blobClient = containerClient.getBlockBlobClient(blobName);
        // uploaded
        await blobClient.uploadBrowserData(blob);
        setRequisitionData({ ...requisitionData, resumeUrl: url }); // to be deleted
        return url
    }

    const locationTextFieldChangeHandler = (e) => {
        setLocationLoading(true)
        setLocationList([])
        setLocationSearchText(e.target.value)
    }
    const locationChangeHandler = (e, value) => {

        if (value) {
            setRequisitionData({ ...requisitionData, location: value });
        } else {
            setRequisitionData({ ...requisitionData, location: [] });
        }
        setLocationList([])
        setLocationSearchText("")
    }

    return (
        <>
            {(userType === "MIS Operator") ? (
                <>
                    <div className='mainContainer_createRequisition'>
                        <div className="topContainer_createRequisition">
                            <Header />
                            <div className="mainDiv_createRequisition">
                                <h2 className="titlesofCommunity">Add Requisition</h2>
                                <div className="goBackToRecruiterDashboard_createRequisition" onClick={(e) => gotoDashBoard(e)}>
                                    <ArrowBackIcon />  &nbsp;{userType === "MIS Operator" ? "Go Back to Requisition Dashboard" : "Go Back to Dashboard"}
                                </div>
                                <div className='mainContainer_div_createRequisition'>
                                    <div className='leftside_maincontainer_div_createRequisition'>
                                        <div className='leftside_topContainer_createRequisition'>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Primary Skills*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="Primary Skills"
                                                                className={requisitionData.primarySkill === "" ? !isSubmited ? "createRequisition_textbox_outline" : "createRequisition_error_outline" : "createRequisition_textbox_outline"}
                                                                value={requisitionData?.primarySkill || ""}
                                                                onChange={primarySkillHandler}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Placement Type*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            className={requisitionData.placementType === "" ? !isSubmited ? "createRequisition_textbox_outline" : "createRequisition_error" : "createRequisition_textbox_outline"}
                                                            options={placementTypeOption}
                                                            getOptionLabel={(option) => option.PositionType || ""}
                                                            value={requisitionData?.placementType || ""}
                                                            onChange={placementTypeHandler}
                                                            filterSelectedOptions
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    required={true}
                                                                    placeholder="Placement Type"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Client JobId</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="Client JobId"
                                                                className={"createRequisition_textbox_outline"}
                                                                value={requisitionData?.clientJobId || ""}
                                                                onChange={clientJobIdHandler}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Client Name*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            className={requisitionData.clientName === "" ? !isSubmited ? "createRequisition_textbox_outline" : "createRequisition_error" : "createRequisition_textbox_outline"}
                                                            getOptionLabel={(option) =>
                                                                option.clientName
                                                                    ? option.clientName
                                                                    : ""
                                                            }
                                                            options={clientNameOption}
                                                            value={requisitionData.clientName || ""}
                                                            onChange={ClientNameHandler}
                                                            filterSelectedOptions
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    required={true}
                                                                    placeholder="Client Name"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>End Client Name</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="End Client Name"
                                                                className={"createRequisition_textbox_outline"}
                                                                value={requisitionData.endClientName || ""}
                                                                onChange={endClientNameHandler}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>POC</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            options={pocName}
                                                            className="createRequisition_textbox_outline"
                                                            getOptionLabel={(option) =>
                                                                option.spocName ? option.spocName : ""
                                                            }
                                                            value={requisitionData.poc || ""}
                                                            onChange={pocChangeHandler}
                                                            filterSelectedOptions
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    // required={true}
                                                                    placeholder="Select POC"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Division*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            options={divisionName}
                                                            className={requisitionData.division === "" ? !isSubmited ? "createRequisition_textbox_outline" : "createRequisition_error" : "createRequisition_textbox_outline"}
                                                            getOptionLabel={(option) =>
                                                                option.divisionName ? option.divisionName : ""
                                                            }
                                                            value={requisitionData.division || ""}
                                                            onChange={divisionChangeHandler}
                                                            filterSelectedOptions
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    required={true}
                                                                    placeholder="Division"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Priority*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            className={requisitionData.priority === "" ? !isSubmited ? "createRequisition_textbox_outline" : "createRequisition_error" : "createRequisition_textbox_outline"}
                                                            options={priority}
                                                            getOptionLabel={(option) =>
                                                                option.name ? option.name : ""
                                                            }
                                                            filterSelectedOptions
                                                            autoHighlight
                                                            value={requisitionData.priority || ""}
                                                            onChange={priorityChangeHandler}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    required={true}
                                                                    placeholder="Priority"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Minimum Bill Rate</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="Minimum Bill Rate"
                                                                className="createRequisition_textbox_outline"
                                                                type='number'
                                                                onChange={minimumbillRateChangeHandler}
                                                                inputProps={{ min: 0 }}
                                                                value={requisitionData?.minimumBillRate || ""}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Maximum Bill Rate</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="Maximum Bill Rate"
                                                                className="createRequisition_textbox_outline"
                                                                type='number'
                                                                onChange={MaximumbillRateChangeHandler}
                                                                inputProps={{ min: 0 }}
                                                                value={requisitionData?.maxiMumBillRate || ""}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Currency Unit</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            className="createRequisition_textbox"
                                                            options={currencyBillRate}
                                                            getOptionLabel={(option) =>
                                                                option.value ? option.value : ""
                                                            }
                                                            filterSelectedOptions
                                                            autoHighlight
                                                            value={requisitionData.currency || ""}
                                                            onChange={currencyBillRateChangeHandler}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    required={true}
                                                                    placeholder="Currency"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {requisitionData.placementType.PositionType !== "Contract" ?
                                                <>
                                                    <div className='form_container_createRequisition'>
                                                        <div className='feildvalue_container'>
                                                            <div className='valueFeild_Text'>Fee(%)</div>
                                                            <div className='valueFeild_createRequisition'>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <OutlinedInput
                                                                        placeholder="Fee"
                                                                        className="createRequisition_textbox_outline"
                                                                        type='number'
                                                                        onChange={feeChangeHandler}
                                                                        inputProps={{ min: 0 }}
                                                                        value={requisitionData?.fee || ""}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ""
                                            }
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Openings*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="Openings"
                                                                className={requisitionData.openings === "" ? !isSubmited ? "createRequisition_textbox_outline" : "createRequisition_error_outline" : "createRequisition_textbox_outline"}
                                                                type='number'
                                                                value={requisitionData?.openings || ""}
                                                                onChange={openingChangeHandler}
                                                                inputProps={{ min: 0 }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form_container_createRequisition_location'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Location*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            className={requisitionData?.placementType == "Contract" ? "createRequisition_textbox_outline" : "createRequisition_textbo"}
                                                            multiple={true}
                                                            limitTags={2}
                                                            loading={locationLoading}
                                                            loadingText={<CircularProgress />}
                                                            options={locationList}
                                                            getOptionLabel={(option) =>
                                                                option ? option : ""
                                                            }
                                                            filterSelectedOptions
                                                            autoHighlight
                                                            value={requisitionData?.location || []}
                                                            onChange={locationChangeHandler}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    value={locationSearchText}
                                                                    onChange={(e) => locationTextFieldChangeHandler(e)}
                                                                    required={true}
                                                                    placeholder="Search locations"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Start Date</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <DatePicker
                                                            className="createRequisition_textbox_datapicker"
                                                            selected={startSelectedDate}
                                                            onChange={startDateChangeHandler}
                                                            value={startSelectedDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            showIcon
                                                            minDate={new Date()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>End Date</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <DatePicker
                                                            className="createRequisition_textbox_datapicker"
                                                            selected={endDateSelectedDate}
                                                            onChange={endDateChangeHandler}
                                                            dateFormat="MM/dd/yyyy"
                                                            showIcon
                                                            minDate={new Date()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Minimum Experience*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="Minimum Experience"
                                                                className={"createRequisition_textbox_outline"}
                                                                type='number'
                                                                value={requisitionData?.minimumExperienceLevel || requisitionData?.minimumExperienceLevel === 0 ? requisitionData?.minimumExperienceLevel : ""}
                                                                onChange={minimumExperienceHandler}
                                                                inputProps={{ min: 0, max: 40 }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Maximum Experience*</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                placeholder="Maximum Experience"
                                                                className={"createRequisition_textbox_outline"}
                                                                type='number'
                                                                value={requisitionData?.maximumExperienceLevel || requisitionData?.maximumExperienceLevel === 0 ? requisitionData?.maximumExperienceLevel : ""}
                                                                onChange={maximumExperienceHandler}
                                                                inputProps={{ min: 0, max: 40 }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form_container_createRequisition'>
                                                <div className='feildvalue_container'>
                                                    <div className='valueFeild_Text'>Status</div>
                                                    <div className='valueFeild_createRequisition'>
                                                        <Autocomplete
                                                            id="multiple-limit-tags"
                                                            className='createRequisition_textbox'
                                                            options={status}
                                                            getOptionLabel={(option) =>
                                                                option.name ? option.name : ""
                                                            }
                                                            filterSelectedOptions
                                                            autoHighlight
                                                            value={requisitionData.status || ""}
                                                            onChange={statusChangeHandler}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    required={true}
                                                                    placeholder="Status"
                                                                />

                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {requisitionData.status.name === "FILLED" ?
                                                <>
                                                    <div className='form_container_createRequisition'>
                                                        <div className='feildvalue_container'>
                                                            <div className='valueFeild_Text'>Filled By</div>
                                                            <div className='valueFeild_createRequisition'>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <OutlinedInput
                                                                        placeholder="Filled By"
                                                                        className="createRequisition_textbox_outline"
                                                                        type='text'
                                                                        onChange={filledByChangeHandler}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ""}
                                        </div>
                                        <div>
                                            <div className='ActionContainer_createRequisition'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className='ButtonSave_createRequisition'
                                                    onClick={(e) => openModel()}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    className='ButtonCancel_createRequisition'
                                                    onClick={cancelHandler}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='rightside_maincontainer_div_createRequisition'>
                                        <div className='middleDiv_mainContainer'>
                                            <div className='feildvalue_container'>
                                                <div className='valueFeild_Text'>Assign To*</div>
                                                <div className='valueFeild_createRequisition'>
                                                    <Autocomplete
                                                        id="multiple-limit-tags"
                                                        className={requisitionData.assignToValue === "" ? !isSubmited ? "createRequisition_textbox_outline" : "createRequisition_error" : "createRequisition_textbox_outline"}
                                                        options={getFilteredRecruiterLeaderOptions()}
                                                        getOptionLabel={(option) =>
                                                            option.email ? option.email : ""
                                                        }
                                                        filterSelectedOptions
                                                        autoHighlight
                                                        value={requisitionData.assignToValue ? requisitionData.assignToValue : ""}
                                                        onChange={assignToChangeHandler}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                required={true}
                                                                placeholder="Assign To"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='feildvalue_container_jobDescription'>
                                                <div className='valueFeild_Text'>Job Description</div>
                                                <div className='valueFeild_createRequisition'>
                                                    <textarea
                                                        type="text"
                                                        TextField="Enter Job Description"
                                                        rows="18"
                                                        className="support_message_createRequisition"
                                                        onChange={jobDescriptionChangeHandler}
                                                        value={requisitionData?.jobDescription || ""}
                                                    />
                                                </div>
                                            </div>

                                            <div className='container_button_fileattachment'>
                                                {!selectedFile.name ?
                                                    <>
                                                        <FilePicker
                                                            onChange={(e) => handleFileSelect(e)}>
                                                            <Button variant="contained" className='bottomcontainer_container_attachment'>
                                                                <AttachFileIcon className='attachfileIcon' /><div>Choose Document</div>
                                                            </Button>
                                                        </FilePicker>
                                                    </> :
                                                    <>
                                                        <div className="uploadFuctionContainer_conditional_createRequition">
                                                            <div className="uploadcontainer_profile_createRequition">
                                                                <div className="uploadFuctionCon_createRequition">
                                                                    <CancelTwoToneIcon onClick={(e) => handleclose()} className="close_icon_createRequisition" />
                                                                    <div className="candidateFileExtention_createRequisition">{fileExtensionuploadResume()}</div>
                                                                </div>
                                                                <FilePicker
                                                                    onChange={(e) => handleFileSelect(e)}
                                                                >
                                                                    <Button
                                                                        className="candidate_filepicker_button_createRequition"

                                                                    >
                                                                        Change Document
                                                                    </Button>
                                                                </FilePicker>

                                                            </div>
                                                        </div>
                                                    </>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottomContainer_createRequisition">
                            <Footer />
                        </div>
                    </div>
                    <Dialog
                        onClose={closeModal}
                        open={modalIsOpen}
                        aria-labelledby="customized-dialog-title"
                        maxWidth="xs"
                        fullWidth={true}
                    >
                        <DialogContent >
                            <div className="fs-semi-bold f-color fs-18">Do you want to submit the requisition?</div>
                        </DialogContent>
                        <DialogActions>
                            <div className='button_Container_CreateRequisition'>
                                <Button variant="contained" color="primary" disabled={isDataSaved} className={isDataSaved === true ? "ButtonSave_createRequisition_dialog_opacity" : "ButtonSave_createRequisition_dialog"} onClick={saveHandler}
                                >
                                    Confirm
                                </Button>
                                <Button variant="contained" color="secondary" className='ButtonCancel_createRequisition' onClick={closeModal}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <NotificationContainer />
                </>
            ) :
                <>
                    <BasicHeader />
                    <div className="row padding_80 text-center">
                        <p className="fs-semi-bold fs-40 f-color authorize">
                            You Are Not Authorized To Visit This Page
                        </p>
                    </div>
                </>

            }
        </>
    )
}

export default CreateRequisition