import axios from "axios";
const jsonConfig = require("../Config.json");

const spocByClientid = async(clientId,recruitmentpartnerId) => {
    try
    {
        
        const response = await axios.get(
            `${jsonConfig.apiUrl}spocByClient?clientId=${clientId}&recruitmentpartnerId=${recruitmentpartnerId}`,
            {
                headers: {
                  "x-functions-key": jsonConfig.key,
                },
            }
        );
        return Promise.resolve(response);
    }
    catch(error)
    {
        return Promise.reject(error);
    }
}

export default spocByClientid