import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
// import axios from "axios";
import React, { useState, useEffect, useCallback, useRef } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router";
import user_register from "../../components/basicInfoForm/images/avatar.png";
import recruiter_sign_thankyou_icon from "../../components/basicInfoForm/images/successBlueTick.png";
import tick_mark from "../../components/basicInfoForm/images/Path 202.png";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import cross from "../../components/profile/profileCompletion/images/cross.svg";
import camera from "../../components/profile/profileCompletion/images/camera.png";
import "date-fns";
import Tooltip from "@material-ui/core/Tooltip";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../components/profile/profileCompletion/cropImage";
import { BlobServiceClient } from "@azure/storage-blob";
import Slider from "@material-ui/core/Slider";
import { FilePicker } from "react-file-picker";
import emailVerificationResponse from "../../apis/otp/emailVerification";
import reSendOtpResponse from "../../apis/otp/reSendOtp";
import verifyOtpResponse from "../../apis/otp/verifyOtp";
import recruiterSignUpResponse from "../../apis/recruiters/recruiterSignUp";
import profilePictureUpdateResponse from "../../apis/profile/profilePictureUpdate";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import "./recruiterSignup.css"
import { FormControl, OutlinedInput } from "@material-ui/core";
import HomeContactUsModal from "../home/homeHeader/homeContactUsModal/homeContactUs";
import "react-phone-number-input/style.css";
import PhoneInput, {  isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import getActiveRoles from "../../apis/users/getActiveRoles";

const useStyles = makeStyles((theme) => ({
  buttonAlign: {
    display: "flex",
    alignItems: "center",
    padding: "24px 42px 24px 42px !important",
  },
  bodyForEditProfile: {
    marginTop: "70px",
  },
  changeProfiletitle: {
    padding: "16px 42px!important",
  },
  changeprofilebody: {},
  otpStyle: {
    caretColor: "transparent",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: "45px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonForProfilePic: {
    position: "absolute",
    right: "8px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleForProfilePic = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <div className="crossFormodal cp" onClick={onClose}>
          <img className="closeicon_set" src={cross} alt='img'></img>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
});
// const DialogContent = withStyles((theme) => ({
//   root: {},
// }))(MuiDialogContent);
const DialogContentForprofilepic = withStyles((theme) => ({
  root: {
    marginTop: "0px!important",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);
// for tooltip
// const useStylesBootstrap = makeStyles((theme) => ({
//   arrow: {
//     color: "#1A8FFF",
//   },
//   tooltip: {
//     backgroundColor: "#1A8FFF",
//     padding: "4px 13px",
//     fontFamily: "Segoe-Semibold",
//     fontSize: "13px",
//   },
// }));

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

const RecruiterSignUp = () => {
  const today = new Date();

  const jsonConfig = require("../../Config.json");
  const classes = useStyles();
  const history = useHistory();
  const [recruiterDataForSignUp, setrecruiterDataForSignUp] = useState({roles:  jsonConfig.customerCode});
  const [verifyOtp, setVerifyOtp] = useState("");
  const [emailError, setemailError] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isEmailDisabled, setisEmailDisabled] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(300000); //300000
  const [emailidId, setemailidId] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isAccountCreated, setisAccountCreated] = useState(false);
  const [profilePictureEditModal, setProfilePictureEditModal] = useState(false);
  const userCameraStreamRef = useRef(null);
  const btnRef = useRef();
  const [display, setdisplay] = useState(false);
  const [modalProfilePictureUrl, setModalProfilePictureUrl] =
    useState(user_register);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [profilePicture, setProfilePicture] = useState(user_register);
  const [profilePicBlob, setprofilePicBlob] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  // const [phoneNoIntnl, setPhoneNoIntnl] = useState("");
  const [phoneError, setPhoneError] = useState(null);
  const [isCompletedClick, setisCompletedClick] = useState(false);
  // const [isVerified, setisVerified] = useState(false);
  const [displayEmail, setDisplayEmail] = useState("");
  const [profileImageAvailable, setprofileImageAvailable] = useState(false)
  const [open, setOpen] = useState(false);
  const hamburger = useRef(0);
  const [hamburgerIsClicked, setHamburgerIsClicked] = useState(false)
  const [defaultCountryCode, setDefaultCountryCode] = useState("")
  // const [allRoles, setallRoles] = useState([]);
  // const [userRoleswithOutCandidate, setUserRoleWithoutCandidate] = useState([]);



  const [countryList, setCountryList] = useState([
    {
      currency: "USD",
      flag: "",
      isoCode: "US",
      latitude: "38.00000000",
      longitude: "-97.00000000",
      name: "United States",
      phonecode: "+1",
    },
    {
      currency: "CAD",
      flag: "",
      isoCode: "CA",
      latitude: "60.00000000",
      longitude: "-95.00000000",
      name: "Canada",
      phonecode: "+1",
    },
    {
      currency: "INR",
      flag: "",
      isoCode: "IN",
      latitude: "20.00000000",
      longitude: "77.00000000",
      name: "India",
      phonecode: "+91",
    },
    {
      currency: "MXN",
      flag: "",
      isoCode: "MX",
      latitude: "23.00000000",
      longitude: "-102.00000000",
      name: "Mexico",
      phonecode: "+52",
    }
  ]);

  useEffect(() => {
    document.body.style.backgroundColor = "white";
    
  });
  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          setCounter((counter) => counter - 1000);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId); ///componentWillUnmount here mounting phase is distroyed
  }, [isActive, counter]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        modalProfilePictureUrl,
        croppedAreaPixels,
        rotation
      );
      var block = croppedImage.split(";");

      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      await b64toBlob(realData, contentType);
      setdisplay(false);
      closeProfilePicModal();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const validateEmail = (email) => {
    const regX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regX.test(email)) {
      if (
        email.includes("gmail") ||
        email.includes("outlook") ||
        email.includes("yahoo") ||
        email.includes("hotmail")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const emailChangeHandler = (event) => {
    setemailError(true);
    const emailValue = event.target.value;
    const emailValidate = validateEmail(emailValue);
    if (emailValidate) {
      setrecruiterDataForSignUp((prev) => ({
        ...prev,
        email: emailValue,
      }));
      setDisplayEmail(emailValue);
      setemailError(false);
    }
  };
  // const gotoHomePage = (e) => {
  //   history.push("/");
  // };
  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      (seconds === 0 ? "00" : seconds)
    );
  };
  const sendOtp = async (e) => {
    const payLoad = {
      email: recruiterDataForSignUp.email,
      isVerified: false,
      phoneNumber: recruiterDataForSignUp.phoneNumber,
      country: recruiterDataForSignUp.country.isoCode,
    };

    try {
      const sendOtpData = await emailVerificationResponse(payLoad);
      setemailidId(sendOtpData.data.id);
      if (sendOtpData.data.message.includes("This email ID already exists")) {
        return NotificationManager.error(
          "User already exists in ClearedTalent",
          "Error",
          2500
        );
      } else {
        setisCompletedClick(true);
      }
      setOtpSent(true);
      setisEmailDisabled(true);
      setIsActive(!isActive);
      NotificationManager.success("OTP sent", "Success", 2500);
      // resendTimer("start")
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const reSendOtp = async () => {
    const payloadData = {
      id: emailidId,
      email: recruiterDataForSignUp.email,
      isVerified: false,
    };
    try {
      // const resendOtpData =
      await reSendOtpResponse(payloadData);
      setIsActive(true);
      setCounter(300000);
      NotificationManager.success("OTP sent", "Success", 2500);
    } catch (error) {
      console.log(error);
    }
  };
  const otpVerification = async () => {
    const payLoad = {
      id: emailidId,
      email: recruiterDataForSignUp.email,
      otpHash: verifyOtp,
    };
    try {
      const verifyOtpData = await verifyOtpResponse(payLoad);
      if (verifyOtpData.data.message.includes("Invalid OTP")) {
        return NotificationManager.error(
          "Please enter valid OTP",
          "Error",
          2500
        );
      } else {
        // setisVerified(true);
        setIsEmailVerified(true);
        createAccount();
        return NotificationManager.success("OTP verified", "Success", 2500);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const goToTerms = (e) => {
    window.open("/terms-of-use");
  };
  const goToPrivacyPolicy = () => {
    window.open("/privacy-policy");
  };
  const countryHandler = (event, value) => {

    if (value) {
      setrecruiterDataForSignUp((prev) => ({
        ...prev,
        country: value,
      }));
      setDefaultCountryCode(value.isoCode)
    }
  };
  // const userTypeHandler =(event, value) =>{
  //   if (value) {
  //     setrecruiterDataForSignUp((prev) => ({
  //       ...prev,
  //       roles: value.roleName,
  //     }));
  //   }
    
  // }
  console.log(recruiterDataForSignUp.roles);
  const phoneNumberChangeHandler = (val) => {
    setPhoneNo(val);
    if (val) {
      if (val && isPossiblePhoneNumber(val)) {
        setPhoneError(false);
        let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
        setrecruiterDataForSignUp((prev) => ({
          ...prev,
          phoneNumber: val.replace(countryCode_PhoneNumber, ""),
          phoneCode: (`+${parsePhoneNumber(val).countryCallingCode}`)
        }));
      } else {
        setrecruiterDataForSignUp((prev) => ({
          ...prev,
          phoneNumber: "",
          phoneCode:""
        }));
        setPhoneError(true);
      }
    }
    else {
      setPhoneError(false);
      setrecruiterDataForSignUp((prev) => ({
        ...prev,
        phoneNumber: "",
        phoneCode:""
      }));
    }
  };

  const firstNameChangeHandler = (e) => {
    let firstName = e.target.value;
    setrecruiterDataForSignUp((prev) => ({
      ...prev,
      firstName: firstName,
      createdBy: "",
      updatedBy: "",
      isActive: false,
    }));
  };
  const organizationChangeHandler = (e) => {
    let organization = e.target.value;
    setrecruiterDataForSignUp((prev) => ({
      ...prev,
      organizationnameduringsignup: organization,
    }));
  };

  const organizationcodeChangeHandler = (e) => {
    let organizationcode = e.target.value;
    setrecruiterDataForSignUp((prev) => ({
      ...prev,
      organizationcodeduringsignup: organizationcode,
    }));
  };
  const lastNameChangeHandler = (e) => {
    let lastName = e.target.value;
    setrecruiterDataForSignUp((prevState) => ({
      ...prevState,
      lastName: lastName,
    }));
  };

  const keypressHandler = (e) => {
    var invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  // const changeEmailId = () => {
  //   setisEmailDisabled(false);
  //   setOtpSent(false);
  //   setVerifyOtp("");
  //   setIsEmailVerified(false);
  //   setIsActive(!isActive);
  //   setCounter(300000);
  // };
  const goToHomePage = () => {
    window.open("/", "_self");
  };
  const TermsCheckboxHandler = (e) => {
    setIsTermsAccepted(e.target.checked);
  };
  const stepOneCreateAccount = async () => {
    if (
      !recruiterDataForSignUp.firstName ||
      !recruiterDataForSignUp.lastName ||
      !recruiterDataForSignUp.organizationnameduringsignup ||
      !recruiterDataForSignUp.phoneCode ||
      !recruiterDataForSignUp.phoneNumber   ||      
      !recruiterDataForSignUp.roles
    ) {                                               
      return NotificationManager.error(
        "Please fill all required fields",
        "Error",
        2500
      );
    }
    if (!isTermsAccepted) {
      return NotificationManager.error(
        "Please agree to terms of use & privacy policy to continue",
        "Error",
        2500
      );
    }
    sendOtp();
  };
  const createAccount = async () => {
    const createRecruiter = await recruiterSignUpResponse(
      recruiterDataForSignUp
    );
    uploadToBlobStorage(profilePicBlob, createRecruiter.data.data.id);
    if (createRecruiter) {
      setisAccountCreated(true);
    }
  };
  // const verifyAccount = () => {
  //   // setisVerified(true);
  // };
  // const goToHome = (e) => {
  //   history.push("/");
  // };
  const openProfilePictureModal = async () => {
    // await getProfileData();
    // if (profileData.profilePicUrl === "" ||
    //   profileData.profilePicUrl === null) {
    //   setModalProfilePictureUrl(noProfilePictureImage);
    // }
    // else {
    //   setModalProfilePictureUrl(profileData.profilePicUrl);
    // }
    setProfilePictureEditModal(true);
  };
  const closeProfilePicModal = async () => {
    //setdisplay(false);
    if (userCameraStreamRef.current) {
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setProfilePictureEditModal(false);
  };
  //-- Cancelling Profile Picture Upload ---
  const cancelProfileUpload = () => {
    if (userCameraStreamRef.current) {
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setprofileImageAvailable(false)
    setProfilePictureEditModal(false);
    setModalProfilePictureUrl(user_register)
    setProfilePicture(user_register)
  }
  const b64toBlob = async (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(
      byteArrays,
      { type: contentType },
      { name: "profile.png" }
    );
    //await uploadToBlobStorage(blob);
    setprofilePicBlob(blob);
    const url = URL.createObjectURL(blob);
    setProfilePicture(url);
  };
  const b64toBlobForCamera = async (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(
      byteArrays,
      { type: contentType },
      { name: "profile.png" }
    );
    const url = URL.createObjectURL(blob);
    setModalProfilePictureUrl(url);
  };
  const filePickerOnChange = async (file) => {
    const downloadedUrl = URL.createObjectURL(file);
    setModalProfilePictureUrl(downloadedUrl);
    setprofileImageAvailable(true)
  };
  const uploadToBlobStorage = async (blob, userId) => {
    const sas = jsonConfig.sasToken;
    // let decodedToken = decodeURI(sas);
    const bsClient = new BlobServiceClient(
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    const containerClient = bsClient.getContainerClient("profilepicture");
    let timeStamp = new Date().getTime();
    const blobName = userId + "/" + "profile_" + timeStamp + ".png";
    const url = `${jsonConfig.ctResourcesCdn}/profilepicture/${blobName}`;
    const blobClient = containerClient.getBlockBlobClient(blobName);
    if (profileImageAvailable === false) {
      return 
    } else {
     await blobClient.uploadBrowserData(blob);
    }
    var dt = new Date();
    if (profileImageAvailable === false) {
      return
    } else {
      var profileimagedata = {
        profilePicUrl: url,
        lastUpdated: dt,
        previousProfielPicUrl: url ? "" : null,
      };
    }
    try {
      const payload = {
        userId: userId,
        profileimagedata: profileimagedata,
      };
      let response 
      if(profileImageAvailable === true) {
       response =  await profilePictureUpdateResponse(payload)
      };
      //NotificationManager.success("Profile picture uploaded", "Success");
      setProfilePicture(response.data.data.profilePicUrl);
      //await getProfileData();
      setProfilePictureEditModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  const constraints = {
    audio: false,
    video: {
      width: 640,
      height: 420,
    },
  };
  const getAllRoles = async () => {
    try {
      const allRoleResponse = await getActiveRoles();
      let roleArray = allRoleResponse.data.data;
      roleArray = roleArray.filter((item) => item.roleName !== "Candidate")
      // setallRoles(allRoleResponse.data.data);
      // setUserRoleWithoutCandidate(roleArray)

    } catch (error) {
      console.log(error);
    }

  };
  useEffect(async()=>{
   await getAllRoles();
  },[])

  const opencameraModal = async () => {
    setdisplay(true);
    const video = document.getElementById("video");
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      window.stream = stream;
      video.srcObject = stream;
      userCameraStreamRef.current = stream;
    } catch (e) { }
  };
  const captureImage = async () => {
    const video = document.getElementById("video");
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    // var a = context.drawImage(video, 0, 0, 640, 480);
    // var b = context.getImageData(0, 0, 640, 480);
    const imageUrl = canvas.toDataURL("image/jpeg");
    var block = imageUrl.split(";");

    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    await b64toBlobForCamera(realData, contentType);
    setprofileImageAvailable(true)
    setdisplay(false);
    userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
  };

  const buttonFocus = (val) => {
    if (val.length === 6) {
      btnRef.current.focus()
    }
  }
  const openSupportModal = () => {
    setOpen(true);
  }

  const _modalClose = () => {
    setOpen(false);
  }
  const hamburgerClicked = () => {
    setHamburgerIsClicked(!hamburgerIsClicked)
  }


  return (
    <>
      <div className="recruiterSignUp">
        <div className="mainBody">
          <div className="topContainer">
            <div className="nav_bg_home home-specific-header">
              <div className="container w1200 nav_bg_home_section">
                <div className="nav_bg_left">

                  <li className='cp headerListStyleNone' onClick={() => goToHomePage()}>
                    <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                  </li>

                </div>
                <div className="nav_bg_right">
                  <ul className="navbar_home">
                    <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='nav_bg_home_responsive'>
              <div className="nav_bg_home_responsive-wrapper">
                <div className="home-top-nav">
                  <li className='cp headerListStyleNone' onClick={() => goToHomePage()}>
                    <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                  </li>
                  <div className={hamburgerIsClicked ? "hamburgerIsClicked" : "hamburgerIsNotClicked"} ref={hamburger}>
                    <ul className="navbar_home-responsive">
                      <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                    </ul>
                  </div>
                  <a className="icon" onClick={hamburgerClicked}>
                    <i className="fa fa-bars"></i>
                  </a>
                </div>
              </div>
            </div>
            {!isAccountCreated ?
              (<Grid container>
                <Grid item xs={12}>
                  <div className="mainHeading">Sign Up as Customer</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="leftContainer">
                    {!isCompletedClick ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className="form-one-left-punch recruiter_signup_form_adjust">
                            <div className="text-center recruiter_profile_wrapper mt_22">
                              <div className="recruiter_profile_image_contaioner">
                                <img
                                  src={profilePicture}
                                  alt='img'
                                  className="profileImage_recruiterSignUp"
                                />
                                <img
                                  src={camera}
                                  className="camera_icon_recruiter cp"
                                  onClick={openProfilePictureModal}
                                  alt='img'
                                />
                              </div>
                            </div>
                            <div>
                              <p className="uploadPictureText">
                                Upload Picture
                              </p>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="inputLabel">First Name*</div>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              placeholder="Enter First Name"
                              className="admin_textbox"
                              onChange={(e) => firstNameChangeHandler(e)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="inputLabel">Last Name*</div>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              placeholder="Enter Last Name"
                              className="admin_textbox"
                              onChange={lastNameChangeHandler}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <div className="inputLabel">Company Email* (OTP verification required)</div>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              placeholder="Enter Email"
                              className="admin_textbox"
                              onChange={(event) =>
                                emailChangeHandler(event)
                              }
                              disabled={isEmailDisabled}
                            />
                          </FormControl>
                          {emailError ? (
                            <p className="form-error fs-regular">
                              Please enter a valid email address
                            </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <div className="inputLabel">Country*

                          </div>
                          <p className="specific_screen1"><br /></p>
                          <Autocomplete
                            id="combo-box-demo"
                            className="mt_14"
                            options={countryList}
                            autoHighlight
                            filterOptions={filterOptions}
                            getOptionLabel={(option) =>
                              option.name
                                ? option.name 
                                : ""
                            }
                            onChange={countryHandler}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                required={true}
                                placeholder="Select Country"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="inputLabel">Phone Number*</div>
                          <div className="select customized-select dropdown_width_recruiter_invite w100 numericContainer">
                            <PhoneInput
                              international
                              defaultCountry={defaultCountryCode}
                              className="textbox_phone_input"
                              placeholder="Enter Phone Number"
                              value={phoneNo}
                              onChange={(val) => phoneNumberChangeHandler(val)}
                              onKeyPress={(e) => keypressHandler(e)}
                            />

                            {/* </FormControl> */}
                            {(phoneError) ? (
                              <p className="form-error fs-regular">
                                Please enter a valid phone number
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="inputLabel">Organization*</div>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              className="admin_textbox"
                              placeholder="Enter Organization Name"
                              onChange={(e) => organizationChangeHandler(e)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="inputLabel">Organization Code</div>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              className="admin_textbox"
                              placeholder="Enter Organization code"
                              onChange={(e) => organizationcodeChangeHandler(e)}
                            />
                          </FormControl>
                        </Grid>         
                        {/* <Grid item xs={12} lg={6}>
                          <div className="inputLabel">User Type*

                          </div>
                          <p className="specific_screen1"><br /></p>
                          <Autocomplete
                            id="combo-box-demo"
                            className="mt_14"
                            options={userRoleswithOutCandidate}
                            autoHighlight
                            getOptionLabel={(option) => option.roleName}
                            onChange={userTypeHandler}
                            renderInput={(params) => (
                              <TextField 
                                {...params} 
                                variant="outlined"
                                required={true}
                                placeholder="Select User Type"
                              />
                            )}
                          />
                        </Grid> */}
                        <Grid item xs={12}>
                          <div className="termsContainer">
                            <div className="video-interview-chkbox basicinfo_chkbox">
                              <input
                                type="checkbox"
                                id="terms"
                                checked={isTermsAccepted}
                                onClick={(e) => TermsCheckboxHandler(e)} />
                              <label htmlFor="terms"></label>
                            </div>
                            <p className="f-color fs-semi-bold fs-16" > I agree to ClearedTalent’s <span className="termsLink" onClick={goToTerms}> Terms of Use </span><span className='text-cyan'> & </span><span className="termsLink" onClick={goToPrivacyPolicy}>Privacy Policy </span></p>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="continue_container">
                            <button onClick={stepOneCreateAccount} className="continue_button">Continue</button>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="note">Note : A field marked with * implies mandatory</div>
                        </Grid>

                      </Grid>
                    ) :
                      (
                        <div>
                          <Grid container>
                            <Grid item xs={12}>
                              <div className="recruiter_signup_otp_left_title">
                                Almost Done!
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className="recievedEmail">
                                Please enter the One Time Password (OTP) that we
                                have sent to your email (
                                {displayEmail ? displayEmail : ""})
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className="otpInput">
                                <input
                                  id="otp-in"
                                  type="tel"
                                  maxLength="6"
                                  value={verifyOtp}
                                  onChange={(e) => {
                                    setVerifyOtp(e.target.value)
                                    buttonFocus(e.target.value)
                                  }}
                                  className={
                                    verifyOtp.length === 6
                                      ? classes.otpStyle
                                      : ""
                                  }
                                  disabled={
                                    !otpSent ? true : isEmailVerified
                                  }
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className="resendCont">
                                {!counter ? <button className="resendBtn" onClick={(e) => reSendOtp(e)}>Resend OTP</button> :
                                  <>
                                    <button className="resendBtn_disabled">Resend OTP</button>
                                    <div className="timer">
                                      {!!counter && (
                                        <p className="resendotp-timer fs-semi-bold">
                                          {millisToMinutesAndSeconds(counter)}{" "}
                                        </p>
                                      )}
                                    </div>
                                  </>
                                }

                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className="verifyContainer">
                                <button className="verifyBtn" onClick={(e) => otpVerification(e)} ref={btnRef}>Verify</button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    }

                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="rightContent">
                    <div className="mainbg_form_recruiter df">
                      <div className="other_formpage_recruiter">
                        <div className="form_text_container_other">
                          <div className="form-one-left-text">
                            <p className="f-blue fs-regular mt_35 df_from1 ">
                              <div className="pr_5">
                                <img
                                  src={tick_mark}
                                  className="tick_mark_adjust"
                                  alt='img'
                                />
                              </div>
                              <div className="f_size_35">
                                <span className="logo_start_color">
                                  ClearedTalent
                                </span>{" "}
                                enables you to screen candidates before presenting
                                them to hiring managers.
                              </div>
                            </p>
                            <p className="f-blue fs-regular mt_35 df_from1 ">
                              <div className="pr_5">
                                <img
                                  src={tick_mark}
                                  className="tick_mark_adjust"
                                  alt='img'
                                />
                              </div>
                              <div className="f_size_35">
                                Invite one or more candidates to take a skill
                                test.
                              </div>
                            </p>
                            <p className="f-blue fs-regular mt_35 df_from1 ">
                              <div className="pr_5">
                                <img
                                  src={tick_mark}
                                  className="tick_mark_adjust"
                                  alt='img'
                                />
                              </div>
                              <div className="f_size_35">
                                Build & manage your own Talent Community.
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>)
              : (
                <>
                  <div className="thanksContainer">
                    <img
                      src={recruiter_sign_thankyou_icon}
                      className="successImage"
                      alt="img"
                    />
                    <div>
                      <p className="line1">
                        Thank you for your interest in <span className="line2">
                          ClearedTalent
                        </span></p>
                      <p className="line3">
                        Once your sign-up request is approved, you will be emailed a
                        temporary password to start using ClearedTalent
                      </p>
                    </div>

                  </div>
                </>
              )}


          </div>

          <div className="footerSection">
            <div className="footer_bg_login">
              <div className="container w1200">
                <div className="container_rec">
                  <div className="footer_left_logo homepage-footer-left-logo">
                    <img src={recruiter_header_logo} className="w-100" alt='img'/>
                  </div>
                  <div className="footer-right">
                    <div className='social_links'>
                      <a href='https://www.linkedin.com/company/clearedtalent/'><Tooltip title="LinkedIn"><LinkedIn className='iconColor' /></Tooltip></a>
                      <a href='https://www.facebook.com/Cleared-Talent'><Tooltip title="Facebook"><Facebook className='iconColor' /></Tooltip></a>
                      <a href='https://twitter.com/Cleared_Talent'><Tooltip title="Twitter"><Twitter className='iconColor' /></Tooltip></a>
                      <a href='https://www.instagram.com/clearedtalent/'><Tooltip title="Instagram"><Instagram className='iconColor' /></Tooltip></a>
                    </div>
                    <div className="footer-links">
                      <div>ClearedTalent © {today.getFullYear()}.&nbsp; All Rights Reserved.</div>
                      <div><span className="footerTerms" onClick={goToTerms}>Terms of Use</span>&nbsp;&nbsp;<span className="footerTerms" onClick={goToPrivacyPolicy}>Privacy Policy </span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <Dialog
          open={profilePictureEditModal}
          onClose={cancelProfileUpload}
          aria-labelledby="customized-dialog-title"
          maxWidth="sm"
          fullWidth={true}
          className="record-video-intro-responsive"
        >
          <DialogTitleForProfilePic
            className={`${classes.changeProfiletitle} responsive-profilepic_title`}
            onClose={cancelProfileUpload}
          >
            Change Profile Picture
          </DialogTitleForProfilePic>

          <DialogContentForprofilepic
            className={`${classes.changeprofilebody} responsive-profilepic_body`}
            changeprofilebody
          >
            {display ? null : (
              <div className="text-center">
                <div className="crop-container" id="circle_ProfilePic">
                  <Cropper
                    image={modalProfilePictureUrl}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="controls">
                  <div className="w-100">
                    <p className="fs-semi-bold fs-15 mt_19 f-color">Zoom</p>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => setZoom(zoom)}
                      classes={{ container: "slider" }}
                    />
                  </div>
                  <div className="w-100">
                    <p className="fs-semi-bold fs-15 mt_19 f-color">
                      Rotation
                    </p>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      aria-labelledby="Rotation"
                      onChange={(e, rotation) => setRotation(rotation)}
                      classes={{ container: "slider" }}
                    />
                  </div>
                </div>
                <div className="profilecamera_upload">
                  <FilePicker
                    extensions={["jpg", "jpeg", "png"]}
                    onChange={filePickerOnChange}
                    onError={(errMsg) => {
                      NotificationManager.error(errMsg, "Error");
                    }}
                  >
                    <button
                      type="button"
                      className="btn change_profile_btn cp "
                    >
                      Upload Picture
                    </button>
                  </FilePicker>
                  <span>
                    <button
                      type="button"
                      className="btn change_profile_btn ml_5 cp "
                      onClick={opencameraModal}
                    >
                      Use My Camera
                    </button>
                  </span>
                </div>
              </div>
            )}
            <div className="camera_section">
              <video
                id="video"
                playsInline
                autoPlay
                style={
                  display
                    ? { display: "block", width: "100%" }
                    : { display: "none" }
                }
              ></video>
              <button
                id="snap"
                className="btn change_profile_btn cp "
                onClick={captureImage}
                style={
                  display
                    ? { marginTop: "26px", marginBottom: "26px" }
                    : { display: "none" }
                }
              >
                Capture
              </button>
              <canvas
                id="canvas"
                width="640"
                height="480"
                style={{ display: "none" }}
              ></canvas>
            </div>
          </DialogContentForprofilepic>
          {display ? null : (
            <>
              <div className="divider_adjust">
                <hr className="new3 mt_20 mb_0" />
              </div>
              <DialogActions
                className={`${classes.buttonAlign} responsive-dialog`}
              >
                <button
                  type="button"
                  className="btn-big btn-skill cp "
                  onClick={cancelProfileUpload}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-big btn-green ml_5 cp "
                  onClick={showCroppedImage}
                >
                  Save
                </button>
              </DialogActions>
            </>
          )}
        </Dialog>

        <HomeContactUsModal
          modalOpen={open}
          modalClose={_modalClose}
        />

        <NotificationContainer />
      </div>
    </>
  );
};

export default RecruiterSignUp;
